@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.box {
    -webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, .7));
    filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, .7));
}

.box:before {
    --mask: conic-gradient(from -45deg at bottom, #0000, #000 1deg 90deg, #0000 90deg) 50% / 40px 100%;
    -webkit-mask: var(--mask);
    mask: var(--mask);
}

.StyledReceipt {
    filter: drop-shadow(0 0 6px rgba(0, 0, 0, 0.2)); /* update the shadow here */
    border-radius: 100;
    position: relative;
    z-index: 0;
  }
  .StyledReceipt:before {
    content:"";
    position:absolute;
    z-index:-1;
    inset:0;
    background: #fff;
    --mask: conic-gradient(from -45deg at bottom,#0000,#000 1deg 90deg,#0000 90deg) 50%/50px 100%;
    -webkit-mask: var(--mask);
            mask: var(--mask);
  }